import { FormEvent, MouseEvent, useCallback } from 'react';

import { NavCarrierFormGroup } from 'app/forms/control/form-group';

export const useOnFormSubmit =
  <F extends NavCarrierFormGroup, SC = {}>(form: F, criteriaSelector: (form: F) => SC, onSuccess: (criteria: SC) => any) => {
    const criteriaSelectorCallback = useCallback(criteriaSelector, []);
    return useCallback((e?: FormEvent | MouseEvent) => {
      if (e) {
        e.preventDefault();
      }
      form.clearServerErrors();
      if (!form.valid) {
        return form.touch();
      }
      onSuccess(criteriaSelectorCallback(form));
    }, [form, criteriaSelectorCallback, onSuccess]);
  };
